import React from 'react'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import { pick } from 'ramda'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useMutation } from '@apollo/client'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { Action } from 'graphql/schemas/action/Action'
import { User } from 'graphql/schemas/user/User'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { Course } from 'graphql/schemas/course/Course'
import { updateActionOrderMutation } from 'graphql/mutations/action/updateAction'
import { isActionCompleted } from 'models/action_models'
import ErrorBoundary from 'components/utils/ErrorBoundary'
import { showModal } from 'components/utils/CustomModal'
import { ActionItem } from 'components/chapter/ActionItem'
import { ALL_ACTIONS } from 'constants/General'
import { client } from 'app'
import { AppState } from 'graphql/queries/app/getAppState'
import { getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { isRtlLanguage } from 'utils/functions'

type ActionsListProps = {
  companyId: string;
  isSequential: boolean;
  engagement: number;
  user: User|undefined;
  app: AppState;
  course: Course;
  actions: Action[];
  study: UserStudy[];
  progress: UserProgress|null;
  hideAssessmentsAfterCompleted: boolean;
  match?: any;

  onClick?: (action: Action) => void;
}

const ActionList = (props: ActionsListProps) => {
  const [updateAction] = useMutation(updateActionOrderMutation)

  if (!props.actions) {
    return (
      <div className="text-center text-white mt-4">
        <div className="uil-ring-css" style={{ transform: 'scale(0.17)' }}><div /></div>
      </div>
    )
  }

  const onSortEnd = (o: { draggableId: string, destination: { index: number, droppableId: string }, source: { index: number, droppableId: string }}) => {
    const action = props.actions.find(a => a.id === o.draggableId)

    if (!action) {
      return showModal({
        title: 'Oops',
        content: intl.get('error_message_generic'),
        secondaryButton: false,
        primaryText: 'Reload',
        primaryAction: () => window.location.reload(),
        secondaryText: 'Contact Support',
        secondaryAction: () => window.location.href = 'mailto:support@gnowbe.com',
      })
    }

    updateAction({
      variables: {
        courseId: action.courseId,
        chapterId: action.chapterId,
        actionId: action.id,
        actionUpdate: {
          order: ((o.destination?.index || 0) + 1),
        },
      },
    })
    // optimistic update
    const d = client.readQuery({
      query: getChaptersForCourseQuery,
      variables: {
        companyId: props.companyId,
        courseId: action.courseId,
        returnAllAssessments: true,
      },
    })
    if (!d) return
    client.writeQuery({
      query: getChaptersForCourseQuery,
      variables: {
        companyId: props.companyId,
        courseId: action.courseId,
        returnAllAssessments: true,
      },
      // if I move down I care about actions ABOVE newIndex
      // if I move up I care about actions ABOVE oldIndex
      data: produce(d, (draft) => {
        const chapterIndex = draft.chapters.findIndex(c => c.id === action.chapterId)
        if (chapterIndex !== -1) {
          draft.chapters[chapterIndex].actions = draft.chapters[chapterIndex].actions.slice().sort((a, b) => a.order - b.order).map((a) => {
            const oldOrder = o.source.index + 1
            const destinationIndex = (o.destination?.index || 0)
            const newOrder = destinationIndex + 1
            if (o.source.index > destinationIndex) { // moving up
              if (a.order <= oldOrder && a.order >= newOrder && a.id !== action.id) {
                return produce(a, (draft) => {
                  draft.order += 1
                })
              }
              if (a.id === action.id) {
                return produce(a, (draft) => {
                  draft.order = newOrder
                })
              }
              return a
            }
            if (destinationIndex > o.source.index) { // moving down
              if (a.order >= oldOrder && a.order <= newOrder && a.id !== action.id) {
                return produce(a, (draft) => {
                  draft.order -= 1
                })
              }
              if (a.id === action.id) {
                return produce(a, (draft) => {
                  draft.order = newOrder
                })
              }
              return a
            }
            return a
          })
        }
      }),
    })
  }

  return (
    <SortableList
      hideAssessmentsAfterCompleted={props.hideAssessmentsAfterCompleted}
      actions={props.actions}
      props={props}
      onSortEnd={onSortEnd}
    />
  )
}

export default ActionList

const SortableList = ({ actions, props, hideAssessmentsAfterCompleted, onSortEnd }: { actions: Action[], props: ActionsListProps, hideAssessmentsAfterCompleted: boolean, onSortEnd }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    paddingBottom: 1,
    outline: 'none',
    ...draggableStyle,
  })

  const sortedActions = actions
    .filter(a => ALL_ACTIONS.some(ac => ac === a.contentType))
    .slice()
    .sort((a, b) => a.order - b.order)

  const lastIncompleteAction = sortedActions.find(a => !isActionCompleted(a.contentType, a.placeholderAnswer || '', props.study.find(s => s.actionId === a.id) || null))

  return (
    <DragDropContext onDragEnd={onSortEnd}>
      <Droppable droppableId="droppable-actions">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            id="real-actionlist" className="px-4 sm:px-0">
            <ErrorBoundary errorElement={<div>Could not load actions</div>}>
              {sortedActions
                .map((action, i) => {
                  const showNext = !props.app.appState.isEditing && lastIncompleteAction?.order === action.order
                  return (
                    <div key={`a-${action.id}`}>
                      {showNext && (
                        <NextActionDivider starting={lastIncompleteAction?.order === 1} />
                      )}

                      <Draggable
                        key={action.id}
                        draggableId={action.id}
                        index={i}
                        isDragDisabled={!props.app.appState.isEditing}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps} // move to child component if you want a dedicated drag handle
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}>
                            <SortableItem
                              props={props}
                              action={action}
                              hideAssessmentsAfterCompleted={hideAssessmentsAfterCompleted}
                              anyPrevActionIncomplete={lastIncompleteAction && lastIncompleteAction.order < action.order || false}
                            />
                          </div>
                        )}
                      </Draggable>
                    </div>
                  )
                })}
            </ErrorBoundary>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

type SortableItemProps = {
  props: ActionsListProps;
  action: Action;
  hideAssessmentsAfterCompleted: boolean;
  anyPrevActionIncomplete: boolean;
}

const SortableItem = ({ props, action, hideAssessmentsAfterCompleted, anyPrevActionIncomplete }: SortableItemProps) => {
  const actionStudy = props.study.find(s => s.actionId === action.id && s.chapterId === action.chapterId)
  const ps = props.progress && props.progress.startedAt || 0

  let actionStatus =
    action.timestamps.createdAt > Math.max(actionStudy && actionStudy.timestamps.updatedAt || 0, ps)
      ? intl.get('new_tag')
      : action.timestamps.updatedAt > Math.max(actionStudy && actionStudy.timestamps.updatedAt || 0, ps)
        || action.timestamps.createdAtTree > Math.max(actionStudy && actionStudy.timestamps.updatedAtTree || 0, ps)
        || action.timestamps.updatedAtTree > Math.max(actionStudy && actionStudy.timestamps.updatedAtTree || 0, ps)
        ? intl.get('updated_tag')
        : ''

  const isSubmitted: boolean = !!(props.progress?.review && props.progress.review.status !== 'notSet')
  if (isSubmitted
    && (actionStudy?.review?.timestamps
    && (actionStudy?.review.timestamps.createdAt > (props.progress?.reviewTimestamps?.updatedAt || 0)
    || actionStudy?.review.timestamps.createdAtTree > (props.progress?.reviewTimestamps?.updatedAtTree || 0)))) {
    actionStatus = intl.get('feedback_tag')
  }

  const rtlDir = isRtlLanguage(props.course.translatedToLanguage) ? { direction: 'rtl', textAlign: 'right' } : {}

  return (
    <ActionItem
      help={props.app.appState.isHelpEnabled}
      companyId={props.companyId}
      enabled={props.app.appState.isEditing || action.order === 1 || !anyPrevActionIncomplete || !props.isSequential}
      engagement={props.engagement}
      graduationGrade={props.course.graduationGrade}
      hideAssessmentsAfterCompleted={hideAssessmentsAfterCompleted}
      first={action.order === 1}
      action={pick(['id', 'courseId', 'chapterId', 'contentType', 'contentUrl', 'audioUrl', 'userAction', 'interactionTime', 'title', 'rewards', 'description', 'placeholderAnswer', 'invisible'], action)}
      study={actionStudy || null}
      user={props.user!}
      isEditing={props.app.appState.isEditing}
      status={actionStatus}
      onClick={props.onClick}
      divDirection={rtlDir}
    />
  )
}

const NextActionDivider = ({ starting }) => {
  return (
    <div id="next-action-divider-wrapper" className="mb-8">
      <div className="hr-sect-gray next-action-divider">
        <div className="border border-deepgray w-14 h-14 rounded-full">
          <div className="flex flex-1 h-full justify-center items-center">
            <span className="icon icon-back text-lg mt-1 inline-block -rotate-90" />
          </div>
        </div>
      </div>

      <div className="next-action-text lowercase first-letter:capitalize">
        <span>{starting ? intl.get('global_start_here') : intl.get('session_next_action').d('Next Action')}</span>
      </div>
    </div>
  )
}
