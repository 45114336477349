import MagicCreditsIcon from "components/icons/MagicCredits"
import { showModal2 } from "components/utils/CustomModal"
import { BASE_API_URL } from "constants/General"
import { useAppState } from "graphql/hooks/useAppState"
import Lottie from "lottie-react"
import React, { useEffect, useRef, useState } from "react"
import intl from "react-intl-universal"
import { MagicCreditsProps, MagicCreditsResponse } from "../types"
import AdditionalInfo from "./AdditionalInfo"
import magicCreditsAnimation from 'common/json/lottie/MagicCreditsAnimation.json'
import { extractGeminiErrorMessage } from 'utils/utils'

const MagicCredits = ({ magicData, creditsTokens, setCredits, showCreditsIndicator, setShowCreditsIndicator, prompt, courseId, organizationId, type, programLength, outlineData }: MagicCreditsProps) => {
  const appState = useAppState()
  const DEBOUNCE_DELAY = 500
  const [creditCount, setCreditCount] = useState(0)
  const [availableCredits, setAvailableCredits] = useState(0)
  const [loading, setLoading] = useState(showCreditsIndicator)
  const controllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    if (!appState) return

    const countProgramChapters = (!courseId && outlineData) ? outlineData.split('Session').length - 1 : undefined
    const validProgramLength = programLength && programLength === 'automatic' ? 6 : Number(programLength)

    const hasValidOutline = outlineData?.trim() !== "";
    const hasOtherChanges =
      magicData.uploadedFilesData ||
      magicData.programLength ||
      magicData.generateAiImages

    if (countProgramChapters === validProgramLength) return

    if (hasValidOutline || hasOtherChanges) {
      const debounceTimer = setTimeout(() => {
        credits()
      }, DEBOUNCE_DELAY)

      return () => clearTimeout(debounceTimer)
    }

  }, [magicData.uploadedFilesData, magicData.programLength, magicData.generateAiImages, outlineData, appState])

  if (!appState) return

  const credits = async () => {
    setLoading(true)
    setShowCreditsIndicator(true)
    // Abort the previous request if it exists
    if (controllerRef.current) {
      controllerRef.current.abort()
    }

    // Create a new AbortController
    const controller = new AbortController()
    controllerRef.current = controller
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/estimateTokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${appState.loggedInAs.token}`,
        },
        body: JSON.stringify({
          uploadedFilesData: magicData.uploadedFilesData,
          prompt: '',
          generateAiImages: magicData.generateAiImages,
          courseId: courseId || '',
          organizationId: organizationId || '',
          type,
          programLength,
          outline: outlineData,
        }),
        signal: controller.signal, // Pass the abort signal
      })

      if (!response.ok) {
        const error = await response.json()
        setShowCreditsIndicator(false)
        setLoading(false)
        throw new Error(error.error.message)
      }

      const result: MagicCreditsResponse = await response.json()

      setCreditCount(result.credits.totalCredits || 0)
      setAvailableCredits(result.credits.availableCredits || 0)
      setCredits(result.credits)
      setShowCreditsIndicator(false)
      setLoading(false)
      controllerRef.current = null
    } catch (err) {
      if ((err as Error).name === "AbortError") {
        console.log("Fetch request was aborted.", err);
        return
      }
      showModal2({
        title: `Error`,
        content: extractGeminiErrorMessage((err as Error)?.message),
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
      setShowCreditsIndicator(false)
      setLoading(false)
    }
  }
  return (
    <div data-test="ai-credits" className="flex flex-row items-center justify-center space-x-1 sm:space-x-4">
      {loading ? <Lottie className="w-10 h-10" animationData={magicCreditsAnimation}/> : <MagicCreditsIcon className="w-10 h-10 fill-magic" />}
      <div className={loading ? 'blur-sm animate-pulse': ''}>
        <div data-test="credit-count" className="font-bold text-xs">{creditCount || creditsTokens.totalCredits} {intl.get('settings_credits')}</div>
        <div data-test="available-credits" className="flex items-center justify-center space-x-2 text-xs">
          <div>
            {availableCredits || creditsTokens.availableCredits} {intl.get('global_available')}
          </div>
          <AdditionalInfo
            tooltipContent={<div>{intl.getHTML('magic_credits_tooltip')}</div>}
            interactive={true} />
        </div>
      </div>
    </div>
  )
}

export default MagicCredits