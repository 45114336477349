import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import { createRoot } from 'react-dom/client'
import intl from 'react-intl-universal'
import { produce, enableMapSet } from 'immer'
import { ApolloProvider, ApolloClient, ApolloLink, split, useQuery } from '@apollo/client'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { HttpLink } from '@apollo/client/link/http'
import { RetryLink } from '@apollo/client/link/retry'
import { Query } from '@apollo/client/react/components'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { BrowserRouter, Route, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import { detect } from 'detect-browser'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { TourProvider } from '@reactour/tour'
import { identifyOnSegment, initSegment, page, track } from 'utils/track'
import { getLoginUrl, logout } from 'actions/auth_actions'
import { changeCurrentCourse, updateTimezone } from 'actions/users_actions'
import Layout from 'components/Layout'
import { showModal } from 'components/utils/CustomModal'
import ScrollToTop from 'components/utils/ScrollToTop'
import { parse, randomSample, filterDupes, doArrow } from 'utils/functions'
import { slog } from 'utils/dateFunctions'
import { GRAPHQL, GRAPHQLS, BASE_API_URL, GNOWBE_SOURCE, APP_STORE_URL, MOBILE_APP_TITLE } from 'constants/General'
import * as logger from 'logger'
import { showAlert } from 'components/utils/Alert'
import Deeplinks from 'components/Deeplinks'
import possibleTypes from 'graphql/schemas/app/possibleTypes.json'

import 'rollbar'
import 'common/stylesheets/main.less'
import 'tippy.js/dist/tippy.css'

const browser = detect()

enableMapSet()

declare let require
declare let COMMIT_HASH

// for zendesk
declare global {
  interface Window {
    zE: any
    zEmbed: any
  }
}

{
  // Load some stuff :)
  const environment = process.env.BUILD || 'development'

  // Rollbar
  initRollbar(environment)

  // Segment
  // for some strange reason, cypress unit tests were not working with segment initialized, this will turn it off only when running tests
  if (typeof Cypress === 'undefined') {
    initSegment(environment)
  }

  try {
    // OneSignal
    // @ts-ignore
    const initOneSignal = () => {
      const settings: any = process.env.ONESIGNAL
      if (typeof settings !== 'object') {
        logger.error('initoneSignal: no settings. skipping')
        return
      }
      // @ts-ignore
      const OneSignal = window.OneSignal || []
      OneSignal.push([
        'init', {
          appId: settings.appId,
          allowLocalhostAsSecureOrigin: true,
          autoRegister: true,
          notifyButton: {
            enable: false,
          },
          persistNotification: false,
          safari_web_id: settings.safari_web_id,
          requiresUserPrivacyConsent: true,
        },
      ])
      OneSignal.push([
        'addListenerForNotificationOpened',
        function (data) {
          track({
            event: 'Push Clicked',
            variables: {
              push_id: data.id,
              platform: 'gnowbe-web',
            },
          })
        },
      ])
    }
    const script = document.createElement('script')
    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js'
    script.async = true
    script.onload = initOneSignal
    document.body.appendChild(script)
  } catch (error) {
    logger.error('OneSignal initialization error', error)
  }

  try {
    // Start of gnowbe Zendesk Widget script
    // tslint:disable-next-line
    window.zE ||
      (function (e, t, s) {
        var n: any =
            (window.zE =
            window.zEmbed =
              function () {
                n._.push(arguments)
              }),
          a: any = (n.s = e.createElement(t)),
          r: any = e.getElementsByTagName(t)[0]
        ;(n.set = function (e) {
          n.set._.push(e)
        }),
          (n._ = []),
          (n.set._ = []),
          (a.async = true),
          a.setAttribute('charset', 'utf-8'),
          (a.src =
            'https://static.zdassets.com/ekr/asset_composer.js?key=' + s),
          (n.t = +new Date()),
          (a.type = 'text/javascript'),
          r.parentNode.insertBefore(a, r)
      })(document, 'script', '534708fc-0694-4741-8f94-4d1f0b156dcc')
    window.zE(() => window.zE.setLocale('en'))

    // Some stupid thing for Zendesk :)
    if (!window['__gCrWeb']) window['__gCrWeb'] = {}
  } catch (error) {
    logger.error('Zendesk initialization error', error)
  }
}

export let client: ApolloClient<NormalizedCacheObject>
export let online: boolean = false
export let offlineSince: number = Number(new Date())

// Fake local storage if not exists (hack for Safari browsers in private mode)
import fls from 'utils/fakeLocalStorage'
import { saveAuthTokens, removeAuthTokens, getAuthTokens } from 'actions/utils'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import {
  getCurrentSubscriptionQuery,
  GetCurrentSubscriptionRes,
  getUserLanguageQuery,
} from 'graphql/queries/user/getUser'
import { initLocalization } from 'utils/lokalisation'
import { ScormWindow } from 'components/ScormWindow'
import { Homescreen } from 'graphql/queries/home/getHomescreenData'
import { HomescreenChanges } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'
// tslint:disable-next-line: no-duplicate-imports
import { identifyOnRollbar, initRollbar } from 'rollbar'
import { GraphQLError } from 'graphql'
import { startRefetchInterval } from 'actions/files'
import { Button } from 'components/utils/Button'
import { UpsellModal } from 'components/modals/UpsellModal'
import { generateMongoId } from 'utils/random'
import { history } from 'utils/history'

if (typeof window.localStorage === 'object') {
  try {
    localStorage.setItem('localStorageTest', '1')
    localStorage.removeItem('localStorageTest')
  } catch (e) {
    fls.fakeLocalStorage()
  }
} else {
  fls.fakeLocalStorage()
}

const LoggedIn = ({ passThruProps }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const {
    data: subData,
    loading: userLoading,
    error: userError,
  } = useQuery<GetCurrentSubscriptionRes>(getCurrentSubscriptionQuery, {
    variables: { userId: appData?.appState.loggedInAs.uid || '' },
  })

  if (window.location.pathname.startsWith('/scorm/')) {
    return (
      <ScormWindow
        currentSubscription={
          subData?.user?.profile?.currentSubscription || undefined
        }
      />
    )
  }

  useEffect(() => {
    startRefetchInterval()
  }, [])

  useEffect(() => {
    if (userLoading || userError) return
    if (!subData?.user) return
    if (
      !appData?.appState.currentCompanyIdCourseId ||
      !subData.user.subscriptions?.some(
        (s) => s === appData?.appState.currentCompanyIdCourseId
      )
    ) {
      let newSub =
        subData.user.profile?.currentSubscription &&
        subData.user.subscriptions?.some(
          (s) => s === subData.user.profile?.currentSubscription
        )
          ? subData.user.profile.currentSubscription
          : randomSample(subData.user.subscriptions || [])

      if (!newSub) {
        newSub = '1-1' // not to have hangs afterwards in app since it doesn't expect nulls here
      }

      updateAppCache('currentCompanyIdCourseId', newSub)
    }
  }, [subData])

  if (userError)
    return <div>{intl.get('error_message_generic')}</div>
  if (userLoading) return <div>{intl.get('loading')}</div>

  return (
    <TourProvider
      // make something more general?
      steps={[]}
      styles={styles}
      badgeContent={({ totalSteps, currentStep }) => (
        <>
          {/* Step {currentStep + 1} of {totalSteps} */}
          {intl.get('steps_dynamic', { 0: currentStep + 1, 1: totalSteps })}
        </>
      )}
      maskClassName="opacity-10"
      className="h-[184px] min-w-[334px]"
      position="right"
      disableFocusLock={true}
      disableDotsNavigation={true}
      prevButton={({ currentStep, setCurrentStep }) => {
        if (currentStep === 0) return null

        return (
          <Button
            onClick={() => {
              setCurrentStep(currentStep - 1)
            }}
            size="small"
            text={intl.get('global_previous')}
            type="text"
          />
        )
      }}
      nextButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        const last = currentStep === stepsLength - 1
        return (
          <Button
            onClick={() => {
              if (last) {
                setIsOpen(false)
                const data = client.readQuery<AppState>({
                  query: getAppStateQuery,
                })
                if (
                  data?.appState.currentTour === 'welcome' &&
                  !subData?.user.features.includes('user-hide_marketing')
                ) {
                  showModal({
                    title: intl.get('onboarding_home_dialog_modal_title'),
                    component: (
                      <div>
                        {intl.get('onboarding_home_dialog_end')}{' '}
                        <button
                          className="text-coral font-medium underline"
                          onClick={() => {
                            if (!subData?.user.onboardingCourse?.joinLink)
                              return
                            window.location.assign(
                              subData.user.onboardingCourse.joinLink
                            )
                          }}
                        >
                          {subData?.user.onboardingCourse.title || intl.get('onboarding_home_dialog_default_program_name')}
                        </button>
                        .
                      </div>
                    ),
                    primaryText: intl.get('global_close'),
                    primaryAction: () => updateAppCache('currentTour', ''),
                    secondaryButton: false,
                  })
                }
              } else {
                setCurrentStep((s) => (s === [].length - 1 ? 0 : s + 1))
              }
            }}
            size="small"
            text={last ? 'Got it!' : 'Next'}
            type="guide"
          />
        )
      }}>
      <Routes>
        <Route path="/d/open" element={<Deeplinks />} />
        <Route path="/d/openChat" element={<Deeplinks />} />
        <Route path="/d/openChat/:urn" element={<Deeplinks />} />
        <Route path="/d/joinGroup/:accessCode" element={<Deeplinks />} />
        <Route path="/d/startExploreCourse/:bundleKey" element={<Deeplinks />} />
        <Route path="/d/referral/:organizationId" element={<Deeplinks />} />
        <Route path="/d/:task" element={<Deeplinks />} />
        <Route path="/d/:task/:companyId" element={<Deeplinks />} />
        <Route path="/d/:task/:companyId/:courseId" element={<Deeplinks />} />
        <Route path="/d/:task/:companyId/:courseId/:chapterId" element={<Deeplinks />} />
        <Route path="/d/:task/:companyId/:courseId/:chapterId/:actionId" element={<Deeplinks />} />
        <Route path="/d/:task/:companyId/:courseId/:chapterId/:actionId/:userId" element={<Deeplinks />} />
        <Route path="/di/open" element={<Deeplinks />} />
        <Route path="/di/openChat" element={<Deeplinks />} />
        <Route path="/di/openChat/:urn" element={<Deeplinks />} />
        <Route path="/di/joinGroup/:accessCode" element={<Deeplinks />} />
        <Route path="/di/:task" element={<Deeplinks />} />
        <Route path="/di/:task/:companyId" element={<Deeplinks />} />
        <Route path="/di/:task/:companyId/:courseId" element={<Deeplinks />} />
        <Route path="/di/:task/:companyId/:courseId/:chapterId" element={<Deeplinks />} />
        <Route path="/di/:task/:companyId/:courseId/:chapterId/:actionId" element={<Deeplinks />} />
        <Route path="/di/:task/:companyId/:courseId/:chapterId/:actionId/:userId" element={<Deeplinks />} />
        <Route path="/scorm/register" element={<Deeplinks />} />
        <Route path="*" element={<Layout {...passThruProps} />} />
      </Routes>
    </TourProvider>
  )
}

let switchUserCancelled = false

const styles = {
  popover: (base, state) => ({
    ...base,
    backgroundColor: '#F7DD79',
    borderRadius: '8px',
    '--reactour-accent': '#000',
    ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
  }),
  badge: (base) => ({
    ...base,
    position: 'absolute',
    left: '30px',
    bottom: '32px',
    top: 'auto',
    background: 'none',
    padding: 0,
    boxShadow: 'none',
    color: '#101010',
    fontFamily: 'NotoSans, NeueHaasUnicaPro, sans-serif',
    fontSize: '.85rem',
  }),
  controls: (base) => ({
    ...base,
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '30px',
    bottom: '25px',
    top: 'auto',
    marginTop: '0',
    '> button': {
      margin: '0 5px',
    },
  }),
  dot: (base) => ({
    display: 'none',
  }),
  close: (base) => ({
    ...base,
    top: '15px !important',
    right: '15px',
    color: '#101010',
  }),
}

const RootRouteHandler = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    page()
  }, [location.pathname])

  history.location = location
  history.navigate = navigate

  return <Query query={getAppStateQuery}>
    {({ data, loading }) => {
      if (loading) {
        return <div>{intl.get('loading')}</div>
      }
      const query = parse(location.search)
      const urlEmail = query.email
        ? decodeURIComponent(query.email)
        : query.continueAs
          ? decodeURIComponent(query.continueAs)
          : ''

      const loggedInAs = data?.appState?.loggedInAs || undefined
      if (urlEmail) {
        // We got an email and user not logged in yet. Lets just start loggin flow.
        if (!loggedInAs?.uid) {
          const loginUrl = getLoginUrl(window.location.href, {email: urlEmail})
          window.location.assign(loginUrl)
          return <div>{intl.get('redirecting')}</div>
        }
        if (urlEmail !== loggedInAs.email && !switchUserCancelled) {
          // We got an email but it does not match to the email of currently logged in user
          const switchConfirmation = confirm(`This link was intended for ${urlEmail} but you are logged in as ${loggedInAs.email}.\n\nDo you want to switch from ${loggedInAs.email} to ${urlEmail}?`)
          if (switchConfirmation) {
            removeAuthTokens()
            const loginUrl = getLoginUrl(window.location.href, {email: urlEmail})
            window.location.assign(loginUrl)
            return <div>{intl.get('redirecting')}</div>
          }
          switchUserCancelled = true
        }
      }

      if (loggedInAs?.uid) {
        identifyOnRollbar(data.appState.loggedInAs.uid, data.appState.loggedInAs.email, COMMIT_HASH)
        return <LoggedIn passThruProps={params} />
      }

      const loginUrl = getLoginUrl(window.location.href)
      window.location.assign(loginUrl)
      return null
    }}
  </Query>
}

const render = (client: ApolloClient<NormalizedCacheObject>, Component) => {
  const container = document.getElementById('app')
  const root = createRoot(container!)
  root.render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ScrollToTop>
          <RootRouteHandler />
        </ScrollToTop>
      </BrowserRouter>
    </ApolloProvider>
  )
}

const getUserLanguage = async (userId: string | undefined) => {
  if (!userId) return undefined

  const { data } = await client.query({
    query: getUserLanguageQuery,
    variables: { userId },
  })

  return data?.user?.language || undefined
}

// Init
async function init() {
  if (window.location.pathname.startsWith('/d/') && browser) {
    if (browser.os === 'iOS' || browser.os === 'Android OS') {
      window.location.replace(
        `${APP_STORE_URL}?$canonical_url=${encodeURIComponent(
          window.location.href
        )}`
      )
      return showModal({
        title: `Opening ${MOBILE_APP_TITLE[process.env.BUILD || 'production']}`,
        content: `We tried opening the ${
          MOBILE_APP_TITLE[process.env.BUILD || 'production']
        } app.<br/><strong>If it opened</strong> you may close this window.<br/><strong>If it didn't open</strong> you can try again.`,
        primaryText: 'Ok',
        secondaryText: 'Try Again',
        secondaryAction: () =>
          window.location.replace(
            `${APP_STORE_URL}?$canonical_url=${encodeURIComponent(
              window.location.href
            )}`
          ),
      })
    }
  }

  // Fixes bug when coming to an url with # inside (session/chapterId#actionId).
  if (!!window.location.hash) {
    const newUrl = window.location.href.replace(/(#.*\?)/, '?')
    window.location.replace(newUrl)
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js', { scope: '/' })
      .then((reg) => {
        console.log(`Registration succeeded. Scope is ${reg.scope}`)
      })
      .catch((error) => {
        // TODO: rudik - you should send this to Rollbar???
        console.log(`Registration failed with ${error}`)
      })
  }

  /**
   * LOGIN
   */
  const parsedQuery = parse(window.location.search)
  const localStorageToken = getAuthTokens()
  let authToken =
    parsedQuery.token_type && parsedQuery.access_token && parsedQuery.expires_at
      ? parsedQuery.access_token
      : localStorageToken || ''
  let authTokenPayload:
    | { aud: string; uid: string; email: string }
    | undefined = authToken ? jwtDecode(authToken) : undefined

  // If token is not "real" token but just auth token, let's get "real"
  if (authTokenPayload && authTokenPayload.aud === 'api.gnowbe.com/auth') {
    slog.i('Exchanging temp token for real one...')
    const res = await fetch(`${BASE_API_URL}/api/v1/auth/login`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    }).catch((err) => {
      return err as Error
    })

    if (res instanceof Error || !res.ok) {
      slog.e('/auth/login returned an error')
      const correlationId = generateMongoId(8)
      alert(intl.get('error_message_generic_login', {0: correlationId }))
      logger.warn('/auth/login returned an error', {
        correlationId,
        statusCode: res instanceof Error ? 0 : res.status,
        error: res instanceof Error ? res : undefined,
      })
      window.location.assign('/auth/login')
      removeAuthTokens()
      return
    }
    const tokenData = await res.json()
    authToken = tokenData.access_token
    authTokenPayload = jwtDecode(authToken)
    const OneSignal = window['OneSignal']
    if (OneSignal && authTokenPayload) {
      const uid = authTokenPayload.uid
      OneSignal.push(() => {
        OneSignal.setExternalUserId(uid)
        OneSignal.sendTag('userId', uid).catch((err) => {
          logger.warn(`Unable to identify user with OneSignal: ${err.message}`)
        })
      })
    }
    if (authTokenPayload) {
      identifyOnSegment(authTokenPayload.uid, authTokenPayload.email)
    }
  }
  saveAuthTokens(authToken)

  if (parsedQuery.access_token && authToken) {
    if (!window.location.pathname.startsWith('/d/')) {
      window.location.replace('/home')
    } else {
      // todo: rudik - remove access_token from url
    }
  }

  /**
   * INIITALIZE APOLLO
   */

  const httpLink = new HttpLink({
    uri: GRAPHQL,
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : '',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })

  const wsClient = new SubscriptionClient(GRAPHQLS, {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      Authorization: authToken ? `Bearer ${authToken}` : '',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })

  const wsLink = new WebSocketLink(wsClient)

  wsClient.onConnected((e) => {
    online = true
  })

  wsClient.onDisconnected((e) => {
    if (!online) return

    online = false
    offlineSince = Number(new Date())
  })

  wsClient.onReconnected((e) => {
    online = true
    if (Number(new Date()) - offlineSince < 15000) return

    const modal = showModal({
      title: 'Welcome Back',
      content:
        '<p>While you were offline the content of the page might have changed.</p><p>Would you like to refresh the content?</p><p class="m-0 p-0 text-coral"><strong>Unsaved changes will be lost.</strong></p>',
      primaryText: 'Refresh Content',
      primaryAction: () => {
        modal.close()
        window.location.reload()
      },
    })
  })

  const retryLink = new RetryLink()

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          getMcStatsForCourse: {
            read(data) {
              return data
            },
            merge(existing = [], incoming) {
              return existing
                ? filterDupes(existing, incoming, '__ref')
                : incoming
            },
          },
          getPostsForSubscription2: {
            read(data) {
              return data
            },
            merge(existing, incoming) {
              if (existing) {
                const updated = produce(existing, (draft) => {
                  draft.posts = incoming.posts
                  draft.users = filterDupes(
                    existing.users,
                    incoming.users,
                    '__ref'
                  )
                  draft.actions = incoming.actions
                  draft.chapters = incoming.chapters
                })
                return updated
              }
              return incoming
            },
          },
          getUserSubscriptions: { merge: false },
          getChaptersForCourse: { merge: false },
        },
      },
      Mutation: {
        fields: {
          submitCourseForReview: {
            keyArgs: ['companyId', 'courseId', 'userId'],
          },
        },
      },
      Subscription: {
        fields: {
          listenHomescreenDataChanges: {
            merge(
              existing: Homescreen,
              incoming: HomescreenChanges,
              { readField, mergeObjects }
            ) {
              return {}
            },
          },
        },
      },
      Homescreen: { keyFields: ['queryStateId'] },
      HomescreenCourseCategory: {
        fields: {
          companies: { merge: false },
          subscriptions: { merge: false },
        },
      },
      CourseEmbedDataUpdated: { keyFields: ['courseId'] },
      CourseTimestampsUpdated: { keyFields: ['courseId'] },
      ProgressReview: {
        fields: {
          reviewers: {
            merge(existing: [], incoming = []) {
              if (!incoming) return []
              return [...incoming]
            },
          },
        },
      },
      ProgressReviewUpdated: { keyFields: ['companyId', 'courseId', 'userId'] },
      ProgressReviewTimestampsUpdated: {
        keyFields: ['companyId', 'courseId', 'userId'],
      },
      Chapter: {
        fields: {
          actions: { merge: false },
        },
      },
      ChapterCreated: { keyFields: ['chapter', ['id']] },
      ChapterUpdated: { keyFields: ['chapter', ['id']] },
      ActionUpdated: { keyFields: ['action', ['id']] },
      Action: {
        fields: {
          rewards: { merge: false },
        },
      },
      ChapterTimestampsUpdated: { keyFields: ['courseId', 'chapterId'] },
      ActionTimestampsUpdated: {
        keyFields: ['courseId', 'chapterId', 'actionId'],
      },
      ChatChannel: {
        keyFields: ['urn'],
        fields: {
          messages: {
            // fix for empty existing coming in with updates
            read(data) {
              return data
            },
            merge(existing = [], incoming) {
              return existing
                ? filterDupes(existing, incoming, '__ref')
                : incoming
            },
          },
        },
      },
      ChatMessageAdded: { keyFields: ['urn'] },
      ChatMessageDeleted: { keyFields: ['urn'] },
      ChatChannelChanges: { keyFields: ['urn'] },
      ChatChannelMember: { keyFields: ['userId'] },
      ChatChannelMemberAdded: { keyFields: ['urn'] },
      ChatChannelInfoNewMessage: { keyFields: ['urn'] },
      ChatChannelInfoChatRead: { keyFields: ['urn'] },
      AppState: { keyFields: [] },
      UserMetadata: { keyFields: [] },
      McStatsAnswer: { keyFields: ['answerId'] },
      CompanyUser: { keyFields: ['userId'] },
      UserCompanyEngSummary: { keyFields: ['userId'] },
      SubscriptionUserBasic: { keyFields: ['userId'] },
      PostLike: { keyFields: ['id', 'userIdLiked'] },
      PostCounts: { keyFields: [] },
      User: {
        fields: {
          subscriptions: {
            merge(existing = [], incoming = []) {
              if (!incoming) return []
              return [...incoming]
            },
          },
        },
      },
      Company: {
        fields: {
          accessCodes: {
            merge(existing = [], incoming = []) {
              if (!incoming) return []
              return [...incoming]
            },
          },
        },
      },
    },
  })

  const handleGraphqlError = (error: GraphQLError) => {
    const { message, path, locations, extensions, source, originalError } =
      error

    if (!!message.match(/User not subscribed/)) {
      return changeCurrentCourse()
    }

    if (path?.includes('company') && extensions?.code === 'BAD_USER_INPUT') {
      return
    }

    if (extensions?.code === 'RESPONSE_WOULD_BE_TOO_BIG') {
      return
    }

    if (extensions?.code === 'LICENSES_EXCEEDED') {
      const type = message.includes('reached the limit of groups') ? 'upgrade_for_more_groups' : 'license'
      const modal = showModal({
        title: intl.get('home_group_invite'),
        component: (
          <UpsellModal
            track="curator_limit"
            type={type}
            hideFeatures={true}
            close={() => modal.close()}
          />
        ),
        onlyContent: true,
        className: 'add-action-modal',
      })
      return
    }

    // single free tier org, bad but what can we do
    if (
      extensions?.code === 'BAD_REQUEST' &&
      message.match(/one free tier organization/)
    ) {
      return showModal({
        title: intl.get('upgrade_for_ft_workspace'),
        content: message,
        primaryText: intl.get('ok'),
        secondaryButton: false,
      })
    }

    if (message && !message.match(/Access denied!/)) {
      showModal({
        title: intl.get('error_title_generic'),
        content: message,
        secondaryButton: false,
      })
    }

    if (!message.match(/at least one/i) && !message.match(/length must be/i)) {
      logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`,
        extensions,
        source,
        originalError
      )
    }

    console.log(error)

    if (extensions) {
      switch (extensions.code) {
        case 'BAD_USER_INPUT': {
          return showAlert(`Error - ${message}`, 'danger')
        }
        case 'FORBIDDEN': {
          return showAlert(
            intl.get('error_not_allow_to_perform_action'),
            'danger'
          )
        }
        case 'INTERNAL_SERVER_ERROR': {
          return
        }
        case 'UNAUTHENTICATED': {
          logout()
          return
        }
      }
    }
  }

  client = new ApolloClient({
    cache,
    name: document.location.hostname,
    version: process.env.COMMIT_HASH,
    assumeImmutableResults: true,
    link: ApolloLink.from([
      // @ts-ignore
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(handleGraphqlError)
        }
        if (networkError) {
          logger.error(`[Network error]: ${networkError}`)
          if (networkError['statusCode'] === 401) {
            slog.e(networkError)
            return logout()
          }
        }
      }),
      retryLink,
      link,
    ]),
  })

  // Default cache data
  cache.writeQuery({
    query: getAppStateQuery,
    data: {
      appState: {
        __typename: 'AppState',
        isEditing: false,
        isLoading: false,
        isHelpEnabled: false,
        isCreatingCourse: false,
        switchToAddedCourse: false,
        isDuplicatingCourse: false,
        isImportingPpt: false,
        search: '',
        loggedInAs: {
          __typename: 'LoggedInAs',
          uid: '',
          email: '',
          token: authToken,
          ...authTokenPayload,
        },
        signatures: '',
        currentCompanyIdCourseId: '',
        activeChapterId: '',
        currentTour: '',
        deeplinkCompanyId: '',
      },
    },
  })

  const userLanguage = await getUserLanguage(
    authTokenPayload?.uid || undefined
  ).catch((err) => {
    logger.error('Error while getting users language. Ignoring!')
    return undefined
  })
  await initLocalization(userLanguage)

  render(client, Layout)
  updateTimezone()
}

init().catch(console.log)

export const updateAppCache = (field, value) => {
  const data = client.readQuery({ query: getAppStateQuery })
  const newData = produce(data, (draft) => {
    draft.appState[field] = value
  })
  client.writeQuery({
    data: newData,
    query: getAppStateQuery,
  })
}
