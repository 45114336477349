import React, { useEffect, useRef, useState } from 'react'
import intl from 'react-intl-universal'
import { useMutation, useQuery } from '@apollo/client'
import { groupBy } from 'ramda'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { MiniLoader } from 'components/utils/MiniLoader'
import { getCourseCategoriesQuery, GetCourseCategoriesQueryRes, getCourseTemplatesQuery } from 'graphql/queries/course/getCourseTemplates'
import { CourseCreateVars, createCourseMutation } from 'graphql/mutations/course/createCourse'
import { CourseDuplicateVars, duplicateCourseMutation } from 'graphql/mutations/course/duplicateCourse'
import { ChaptersUnlocked, Course, CourseDifficulty } from 'graphql/schemas/course/Course'
import { updateAppCache } from 'app'
import { slog } from 'utils/dateFunctions'
import { track } from 'utils/track'
import { GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { UpsellModal } from 'components/modals/UpsellModal'
import { showModal } from 'components/utils/CustomModal'
import { Button } from 'components/utils/Button'
import './waterfall.scss'
import { getMeta, isColorDark } from 'utils/functions'
import { useAppState } from 'graphql/hooks/useAppState'
import { HomescreenData } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'
import { getHomescreenData } from 'graphql/queries/home/getHomescreenData'
import { PresignedUrl, getPresignedUrl } from 'actions/files'
import { ImportPPTCourseVars, importPPTCourseMutation } from 'graphql/mutations/course/importPPTCourse'
import { client } from 'app'
import { removeFileFromIndexedDB, retrieveFileFromIndexedDB, saveFileToIndexedDB } from 'utils/utils'
import { SendUserLockedFeatureEmailVars, sendUserLockedFeatureEmailMutation } from 'graphql/mutations/emails/sendUserLockedFeatureEmail'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { useWhiteLabel } from 'context/whiteLabel'

export const CoursePicker = ({ onNew, onMagic, onPowerpoint, onTemplate, onScorm, onClose, orgData }) => {
  const activeWorkspaceId = useActiveWorkspaceId()
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()
  const magicProgramCreationEnabled = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('generateCourseAi')

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { data: subData, loading: subDataLoading } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  useEffect(() => {
    if (subData?.user.metadata) {
      setWhiteLableActiveWorkspaceId(activeWorkspaceId)
    }
  }, [subData?.user.metadata, activeWorkspaceId, setWhiteLableActiveWorkspaceId])

  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  // const { data: userData } = useQuery(getUserSubscriptionQuery, {
  //   skip: !appData,
  //   variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  // })
  const { data, loading } = useQuery<CourseTemplatesRes>(getCourseTemplatesQuery, { variables: { type: 'editorial' }})
  const [sendUserLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendUserLockedFeatureEmailVars>(sendUserLockedFeatureEmailMutation)


  if (loading || subDataLoading) {
    return (
      <div className="flex flex-col h-full">
        <div className="h-full p-4 overflow-y-auto" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
          <MiniLoader />
        </div>
      </div>
    )
  }

  const canUploadPpt = subData?.user.features.includes('course-from_ppt')
  const canUploadScorm = data?.templates.canCreateScormCourse || false
  const creating = [intl.get('an_ebook'), intl.get('an_online_course'), intl.get('a_digital_guide') , intl.get('an_event_companion'), intl.get('a_presentation'), intl.get('a_workshop'), intl.get('a_coaching_experience')]

  return (
    <>
      <div className="h-full px-4 py-6 sm:px-6" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
        <div className="mb-8">
          <img src="/images/gnowbefy.svg" className="block h-10" />
          <div className="flex">
            <div className="font-medium text-3xl mt-4 mb-2 inline-block">{intl.get('create')}</div>
            <div className="waterfall font-medium text-3xl mt-2.5 ml-2">
              <ul>
                <li>{intl.get('a_program')}</li>
                {creating.sort((a, b) => { return 0.5 - Math.random() }).map((word, index) => {
                  return <li key={index}>{word}</li>
                })}
                <li>{intl.get('a_program')}</li>
                <li></li>
              </ul>
            </div>
          </div>
          <div className="text-sm font-normal">{intl.get('create_program_message')}</div>
        </div>

        <ul className="space-y-4 modal-create-program-by-choice">
          <li tabIndex={0} onClick={onNew} id="create-your-own" className="flex items-center bg-white/50 hover:bg-white p-6 rounded-md border border-gray-300 border-r-[8px] border-r-[#E8871E] cursor-pointer">
            <img src="/images/icon-edit-light.svg" className="w-8 h-8 mr-6 inline-block" />

            <div>
              <div className="font-bold text-lg">{intl.getHTML('program_template_create_own')}</div>
              <div className="text-sm">{intl.get('program_template_create_own_message')}</div>
            </div>
          </li>

          {magicProgramCreationEnabled && <li tabIndex={0} onClick={onMagic} id="create-with-magic-ai" className="flex items-center bg-white/50 hover:bg-white p-6 rounded-md border border-gray-300 border-r-[8px] border-r-magic cursor-pointer">
            <img src="/images/wand-magic-sparkles.svg" className="w-8 h-8 mr-6 inline-block" />

            <div>
              <div className="font-bold text-lg">{intl.get('gnowbe_ai_magic_creator')}</div>
              <div className="text-sm">{intl.get('gnowbe_ai_magic_creator_description')}</div>
            </div>
          </li>}

          {data && (
            <li tabIndex={0} onClick={onTemplate} className="flex items-center bg-white/50 hover:bg-white p-6 rounded-md border border-gray-300 border-r-[8px] border-r-[#61988E] cursor-pointer">
              <img src="/images/icon-file-edit-light.svg" className="w-8 h-8 mr-6 inline-block" />

              <div>
                <div className="font-bold text-lg">{intl.getHTML('program_template_select_title')}</div>
                <div className="text-sm">{intl.get('program_template_message')}</div>
              </div>
            </li>
          )}

          <li tabIndex={0} onClick={() => {
            if (!canUploadPpt) {
              onClose()
              const modal = showModal({
                title: intl.get('home_group_invite'),
                component: <UpsellModal type="starter" track="import_powerpoint" close={() => modal.close()} />,
                onlyContent: true,
                className: 'add-action-modal',
              })
              return
            }
            onPowerpoint()
          }} className="flex items-center bg-white/50 hover:bg-white p-6 rounded-md border border-gray-300 border-r-[8px] border-r-[#6B818C] cursor-pointer">
            <img src="/images/icon-ppt-upload.svg" className="w-8 h-8 mr-6 inline-block" data-test="create-from-ppt-btn"/>

            <div className="flex-1">
              <div className="font-bold text-lg">{intl.getHTML('program_template_import_powerpoint')}</div>
              <div className="text-sm">{intl.get('program_template_import_powerpoint_message')}</div>
            </div>

            {!canUploadPpt && (
              <span className="text-sm icon icon-lock-solid" />
            )}
          </li>

          <li tabIndex={0} onClick={() => {
            if (!canUploadScorm) {
              onClose()
              const modal = showModal({
                title: intl.get('home_group_invite'),
                component: <UpsellModal type="upgrade_for_scorm" track={null} close={() => modal.close()} />,
                onlyContent: true,
                className: 'add-action-modal',
              })
              sendUserLockedFeatureEmail({
                variables: {
                  feature: 'feature-locked-scorm',
                  organizationId: activeWorkspaceId
                 },
              })
              return
            }
            onScorm()
          }} className="flex items-center bg-white/50 hover:bg-white p-6 rounded-md border border-gray-300 border-r-[8px] border-r-[#CB807D] cursor-pointer">
            <img src="/images/icon-upload-light.svg" className="w-8 h-8 mr-6 inline-block" />

            <div className="flex-1">
              <div className="font-bold text-lg">{intl.getHTML('program_template_import_scorm')}</div>
              <div className="text-sm">{intl.get('program_template_import_scorm_message')}</div>
            </div>

            {!canUploadScorm && (
              <span className="text-sm icon icon-lock-solid" />
            )}
          </li>
        </ul>
      </div>
    </>
  )
}

type CourseTemplatesRes = {
  templates: {
    courses: Course[];
    canCreateScormCourse: boolean;
  };
}

export const TemplatePicker = ({ onPrevious, onClose }) => {
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()
  const [selectedTemplate, setSelectedTemplate] = useState<Course|null>(null)
  const { data, loading, error } = useQuery<CourseTemplatesRes>(getCourseTemplatesQuery, { variables: { type: 'editorial' }})
  const { data: categoryData, loading: categoryLoading } = useQuery<GetCourseCategoriesQueryRes>(getCourseCategoriesQuery)

  const [duplicate] = useMutation<{ duplicateCourse: { id: string, testCompanyId?: string } }, CourseDuplicateVars>(duplicateCourseMutation, {
    onCompleted: ({ duplicateCourse }) => {
      track({
        event: 'Course Created',
        variables: {
          title: selectedTemplate?.title,
          course_id: duplicateCourse.id,
          duplicate_from: selectedTemplate?.id || 'unknown',
        },
      })
      onClose()
    },
    onError: () => {
      slog.e('Error while creating program.')
    },
  })

  const duplicateCourse = (template) => {
    if (!template) return

    setSelectedTemplate(template)
    updateAppCache('isCreatingCourse', true)
    updateAppCache('isLoading', true)

    duplicate({
      variables: {
        courseId: template.id,
        courseDuplicate: {
          title: template.title,
          language: template.language || 'en',
          difficulty: template.difficulty as CourseDifficulty,
          titleEnglish: template.titleEnglish || template.title,
          category: template.category || 'LS',
          categoryText: template.category === 'CS' ? template.categoryText || '' : '',
          chaptersUnlocked: template.chaptersUnlocked as ChaptersUnlocked,
        },
      },
    })
  }

  if (loading || categoryLoading) {
    return (
      <div className="flex flex-col h-full">
        <div className="h-full p-4 overflow-y-auto" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
          <MiniLoader />
        </div>
      </div>
    )
  }

  if (error) {
    return <div>Error! ${error.message}</div>
  }

  const groupedByCategory = groupBy(c => c.category || 'notSet', data?.templates?.courses || [])

  const accentColor = whiteLabelData.accentColor

  return (
    <div className="flex flex-col h-full">
      <div className="h-full px-4 py-6 sm:px-6 overflow-y-auto" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
        <div className="mb-8">
          <button
            style={accentColor ? { '--accent-color': accentColor } as React.CSSProperties : {}}
            className={`flex gap-2 font-bold ${accentColor ? `text-[var(--accent-color)]` : 'text-actions-multiple_choice'}`}
            onClick={onPrevious}>
            <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
          </button>

          <div className="font-medium text-3xl mt-8 mb-2">{intl.get('program_template_select_title')}</div>
          <div className="text-sm font-normal">{intl.get('program_template_message')}</div>
        </div>

        <ul className="space-y-2">
          {Object.keys(groupedByCategory).slice().sort((a, b) => a.localeCompare(b)).map((v) => {
            const category = categoryData?.categories.find(c => c.id === v)

            return groupedByCategory[v]?.map(t => (
              <li
                key={t.id}
                tabIndex={0}
                className={`flex items-center bg-white/50 hover:bg-white px-6 py-4 rounded-md border border-gray-300 border-r-[8px] border-r-[${category?.color}] cursor-pointer`}
                onClick={e => duplicateCourse(t)}>
                <div>
                  <div className="font-bold text-lg">{t.title}</div>
                  <div className="text-sm">{category?.title}</div>
                </div>
              </li>
            ))
          })}
        </ul>
      </div>
    </div>
  )
}

type CourseFormProps = {
  isScorm?: boolean|undefined;
  isPowerpoint: boolean|undefined;
  onPrevious: () => void;
  onClose: () => void;
  creatorInOrganizationsLength: number;
}

export const CourseForm = ({ isScorm, isPowerpoint, onPrevious, onClose, creatorInOrganizationsLength }: CourseFormProps) => {
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()
  const appState = useAppState()
  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const { data: home } = useQuery<HomescreenData | undefined | null>(getHomescreenData)

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [uploadPpt] = useMutation<{}, ImportPPTCourseVars>(importPPTCourseMutation)


  const [title, setTitle] = useState(intl.get('new_course'))
  const [isCreating, setIsCreating] = useState(false)
  const [shareProgram, setShareProgram] = useState(false)
  const [selectedPptFile, setSelectedPptFile] = useState<File | null>(null)
  const pptFileInputRef = useRef<HTMLInputElement>(null)

  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  const [create] = useMutation<{ createCourse: Course }, CourseCreateVars>(createCourseMutation, {
    onCompleted: async ({ createCourse }) => {
      track({
        event: 'Course Created',
        variables: {
          course_id: createCourse.id,
          title: createCourse.title,
        },
      })

      if (isPowerpoint) {
        const storedPptFile = await retrieveFileFromIndexedDB('ppt-import-file')

        if (storedPptFile) {
          const presignedUrl: PresignedUrl = await getPresignedUrl(
            appData?.appState.loggedInAs.token || '',
            storedPptFile.name,
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            storedPptFile.size,
            'imports:pptImport',
          )

          onClose()
          let modal

          const xhr = new XMLHttpRequest()

          xhr.upload.addEventListener('loadstart', () => {
            modal = showModal({
              title: intl.get('uploading'),
              content: intl.get('program_create_import_file_loading'),
              hideFooter: true,
            })
          })

          xhr.addEventListener('load', () => {
            modal && modal.close()
            if (xhr.status === 200) {
            uploadPpt({ variables: { courseId: createCourse.id, pptUrl: presignedUrl.data.url } })

            setTimeout(() => {
              modal && modal.close()
              showModal({
                title: intl.get('success'),
                component: (
                  <>
                    <div>The file was successfully uploaded.</div>
                    <div className="my-4 font-bold">
                      Processing might take a while depending on the number of slides in the PPT file.
                    </div>
                    <div>
                      If you don't see the new session after refreshing the content, please wait a while and reload the
                      page.
                    </div>
                  </>
                ),
                primaryText: 'Refresh content',
                primaryAction: () => client.refetchQueries({ include: ['GetChaptersForCourse'] }),
                secondaryButton: false,
              })
            }, 3000)
            } else {
              showModal({
                title: intl.get('error_dialog_title'),
                content: intl.get('error_message_generic'),
                primaryText: intl.get('ok'),
                secondaryButton: false,
              })
            }
          })

          xhr.addEventListener('error', (err) => {
            showModal({
              title: intl.get('error_dialog_title'),
              content: intl.get('error_message_with_error', { 0: err }),
              primaryText: intl.get('ok'),
              secondaryButton: false,
            })
          })

          xhr.open('PUT', presignedUrl.data.presignedUrl)

          Object.entries(presignedUrl.data.headers).map((h) => {
            const [header, value] = h
            if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
            xhr.setRequestHeader(header, value)
          })

          xhr.send(storedPptFile)

          removeFileFromIndexedDB('ppt-import-file')
        }
      }

      onClose()
    },
    onError: () => {
      slog.e('Error while creating program.')
    },
  })

  useEffect(() => {
    if (!home?.homescreenData.courseCategories.some(cat => cat.subscriptions.length)) {
      setShareProgram(true)
    }
  }, [])

  useEffect(() => {
    const updateOrSaveFile = async () => {
      if (selectedPptFile) {
        saveFileToIndexedDB(selectedPptFile, 'ppt-import-file')
      }
    }
    updateOrSaveFile()
  }, [selectedPptFile])

  const handleCheckboxChange = () => {
    setShareProgram(!shareProgram)
  }

  const handlePptFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      alert('no selected file')
      return
    }
    const file = event.target.files?.[0] || null
    setSelectedPptFile(file)
  }

  const handlePptBoxClick = () => {
    pptFileInputRef?.current?.click()
  }

  const createCourse = () => {
    if (isCreating) return

    setIsCreating(true)
    updateAppCache('isCreatingCourse', true)
    updateAppCache('isLoading', true)

    create({
      variables: {
        courseCreate: {
          title: title || intl.get('new_course'),
          language: 'EN',
          difficulty: 'basic',
          titleEnglish: intl.get('new_course'),
          category: 'LF',
          categoryText: '',
          chaptersUnlocked: 'unlocked',
          type: isScorm ? 'scorm' : 'gnowbe',
          // organizationId: shareProgram ? activeWorkspaceId : undefined,
          organizationId: activeWorkspaceId,
        },
        createFirstChapter: !isScorm,
      },
    })
  }

  let header = intl.get('program_template_create_own')
  let subheader = intl.get('program_template_create_own_message')

  if (isScorm) {
    header = intl.get('program_template_import_scorm')
    subheader = intl.get('program_template_import_scorm_message')
  }

  if (isPowerpoint) {
    header = intl.get('program_template_import_powerpoint')
    subheader = intl.get('program_template_import_powerpoint_message')
  }

  const primaryColor = whiteLabelData.primaryColor
  const accentColor = whiteLabelData.accentColor

  return (
    <div className="flex flex-col h-full">
      <div className="px-4 overflow-y-auto flex-1 sm:px-6" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
        <div className="space-y-6 pt-6 pb-5 h-full">
          <div className="mb-8">
            <button
              style={accentColor ? { '--accent-color': accentColor } as React.CSSProperties : {}}
              className={`flex gap-2 font-bold ${accentColor ? `text-[var(--accent-color)]` : 'text-actions-multiple_choice'}`}
              onClick={onPrevious}>
              <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
            </button>

            <div className="font-medium text-3xl mt-8 mb-2">{header}</div>

            <div className="text-sm font-normal">{subheader}</div>

            {isScorm && (
              <div className="text-sm font-bold mt-1">You will be able to choose a SCORM file in the next step.</div>
            )}
          </div>
          <div id="name-your-program" className="space-y-6">
            <div className="space-y-1">
              <label htmlFor="program_name" className="block font-medium leading-5">
                {intl.get('program_title')}
              </label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  id="program_name"
                  placeholder="Enter program title..."
                  className="shadow-sm focus:ring-blue-300 focus:border-blue-300 block w-full sm:text-sm border-team rounded-md"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>
            </div>
            {creatorInOrganizationsLength <= 1 && <>
              <div className="flex">
                <input type="checkbox" id="share-to-default" className="h-4 w-4 my-auto rounded border-gray-300 text-lake focus:ring-lake cursor-pointer" checked={shareProgram} onChange={handleCheckboxChange} />
                <label htmlFor="share-to-default" className="my-auto pb-0 text-base pl-4 cursor-pointer">{intl.get('automatically_share_program')}</label>
              </div>
              <div>
                {!shareProgram
                ? <div className="mt-6 text-sm">{intl.get('accessible_program_in_sandbox')}</div>
                : <div className="mt-6 text-sm">{intl.get('accessible_program_in_default_group')}</div>}
              </div>
            </>
            }

            {isPowerpoint && (
              <>
                <div className="mt-1 block font-medium leading-5">
                  {intl.get('create_program_upload_powerpoint_file')}
                  <div className="w-full border border-dashed bg-white border-gray-300 p-14 flex flex-col items-center justify-center space-y-2 mt-1 hover:cursor-pointer" onClick={handlePptBoxClick}>
                    <svg className="w-12 h-12 text-gray-600" viewBox="0 0 48 49" fill="none"><g clipPath="url(#clip0_1_2059)"><path d="M20 32.5H28C29.1 32.5 30 31.6 30 30.5V20.5H33.18C34.96 20.5 35.86 18.34 34.6 17.08L25.42 7.9C24.64 7.12 23.38 7.12 22.6 7.9L13.42 17.08C12.16 18.34 13.04 20.5 14.82 20.5H18V30.5C18 31.6 18.9 32.5 20 32.5ZM12 36.5H36C37.1 36.5 38 37.4 38 38.5C38 39.6 37.1 40.5 36 40.5H12C10.9 40.5 10 39.6 10 38.5C10 37.4 10.9 36.5 12 36.5Z" fill="#1B585E"/></g></svg>
                    <span className="text-actions-assessment border-b border-actions-assessment">Upload File</span>
                    <span className="text-medgray pt-2 text-sm">Accepted format: .pptx</span>
                    <input id="import-ppt-input" ref={pptFileInputRef} type="file" accept=".pptx"className="hidden" onChange={handlePptFileChange}/>
                    <span>{pptFileInputRef.current?.files?.[0]?.name ?? ''}</span>
                  </div>
                </div>
              </>
            )}

            <Button
              id="name-your-program-create-button"
              type="team"
              style={primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}}
              className={`${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'}`}
              text={isCreating ? intl.get('loading') : intl.get('create_program_button')}
              disabled={(isCreating || !title) || (isPowerpoint && !selectedPptFile)}
              icon="icon-pencil-light"
              onClick={createCourse}
            />

          </div>

        </div>
      </div>
    </div>
  )
}
